import { GET_APPLICATIONS, REJECT_APPLICANT } from "./applications.type";

// syntax for adding new key-values pairs to an object
const applicantsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_APPLICATIONS:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      console.log("Reducer data", action.payload);
      return {
        ...state,
        ...{
          applicants: action.payload.application,
        },
      };

    case REJECT_APPLICANT:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      let new_state = state.applicants.applicants;
      new_state.array.forEach((element) => {
        if (element.id === action.payload.id) {
          element.client_approval_status = "Accpeted";
          element.status = action.payload.status;
        }
      });
      return {
        ...state,
        ...state.applicants,
        new_state,
      };
    default:
      return state;
  }
};
export default applicantsReducer;
