import { GET_APPLICATIONS } from "./applications.type";

// syntax for adding new key-values pairs to an object
const applicationsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_APPLICATIONS:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      console.log("Reducer data", action.payload);
      return {
        ...state,
        ...{
          applications: action.payload.application,
        },
      };
    default:
      return state;
  }
};
export default applicationsReducer;
