import { lazy, Suspense } from "react";
import { Route, Switch, Redirect, HashRouter } from "react-router-dom";
import Header from "./layout/header/header";
import CircleLoader from "./common/circle-loader";
import AgencyPrivateRoute from "./routes/AgencyPrivateRoute";
import AgencySidebar from "./layout/sidebar/agency-sidebar";
import BranchSidebar from "./layout/sidebar/branch-sidebar";
import BranchPrivateRoute from "./routes/BranchPrivateRoute";
import ClientSidebar from "./layout/sidebar/client-sidebar";
import ClientPrivateRoute from "./routes/ClientPrivateRoute";
import history from "./history";
//   {/**************************** Basic Links ***************************/}
const Home = lazy(() => import("./layout/home"));
const Contact = lazy(() => import("./layout/contact/contact"));
const SignIn = lazy(() => import("./layout/auth/signin/signIn"));

//   {/**************************** Agency Links ***************************/}
const AgencyDashboard = lazy(() => import("./layout/agency/dashboard"));
const Branch = lazy(() => import("./layout/agency/branch/branch"));
const BranchDetails = lazy(() =>
  import("./layout/agency/branch/branch-details")
);
const AddBranch = lazy(() =>
  import("./layout/agency/branch/add-branch/add-branch")
);
const UpdateBranch = lazy(() =>
  import("./layout/agency/branch/update-branch/update-branch")
);
const Licenses = lazy(() => import("./layout/agency/license/licenses"));
const AgencyProfile = lazy(() => import("./layout/agency/profile"));

//   {/**************************** Branch Links ***************************/}
const BranchDashboard = lazy(() => import("./layout/branch/dashboard"));
const Client = lazy(() => import("./layout/branch/client/client"));
const ClientDetails = lazy(() =>
  import("./layout/branch/client/client-details")
);
const AddClient = lazy(() =>
  import("./layout/branch/client/add-client/add-client")
);
const UpdateClient = lazy(() =>
  import("./layout/branch/client/update-client/update-client")
);
const Jobs = lazy(() => import("./layout/branch/jobs/jobs"));
const JobSeeker = lazy(() => import("./layout/branch/jobseeker/jobseeker"));
const Applications = lazy(() =>
  import("./layout/branch/applications/Applications")
);
const JobseekerDetails = lazy(() =>
  import("./layout/branch/jobseeker/jobseeker-details")
);
const BranchProfile = lazy(() => import("./layout/branch/profile"));

//   {/**************************** Client Links ***************************/}

const ClientDashboard = lazy(() => import("./layout/client/dashboard"));
const Jobseeker = lazy(() => import("./layout/client/jobseeker/jobseeker"));
const PostedJobs = lazy(() => import("./layout/client/posted-jobs"));
const ClientProfile = lazy(() => import("./layout/client/profile"));
const JobPostMain = lazy(() =>
  import("./layout/client/job-post/job-post-main")
);
const ClientApplications = lazy(() =>
  import("./layout/client/job-applications/Applications")
);

export default function Routes() {
  return (
    <HashRouter history={history}>
      <Header />
      <Suspense fallback={<CircleLoader />}>
        <Switch>
          {/**************************** Agency Routes ***************************/}
          <AgencyPrivateRoute exact path="/agency">
            <AgencySidebar />
            <AgencyDashboard />
          </AgencyPrivateRoute>
          <AgencyPrivateRoute exact path="/agency/branch">
            <AgencySidebar />
            <Branch />
          </AgencyPrivateRoute>
          <AgencyPrivateRoute exact path="/agency/branch/branch_details">
            <AgencySidebar />
            <BranchDetails />
          </AgencyPrivateRoute>
          <AgencyPrivateRoute exact path="/agency/branch/add_branch">
            <AgencySidebar />
            <AddBranch />
          </AgencyPrivateRoute>
          <AgencyPrivateRoute exact path="/agency/branch/update_branch">
            <AgencySidebar />
            <UpdateBranch />
          </AgencyPrivateRoute>
          <AgencyPrivateRoute exact path="/agency/license">
            <AgencySidebar />
            <Licenses />
          </AgencyPrivateRoute>
          <AgencyPrivateRoute exact path="/agency/settings">
            <AgencySidebar />
            <AgencyProfile />
          </AgencyPrivateRoute>
          {/**************************** Branch Routes ***************************/}
          <BranchPrivateRoute exact path="/branch">
            <BranchSidebar />
            <BranchDashboard />
          </BranchPrivateRoute>
          <BranchPrivateRoute exact path="/branch/client">
            <BranchSidebar />
            <Client />
          </BranchPrivateRoute>
          <BranchPrivateRoute exact path="/branch/client/client_details">
            <BranchSidebar />
            <ClientDetails />
          </BranchPrivateRoute>
          <BranchPrivateRoute exact path="/branch/client/add_client">
            <BranchSidebar />
            <AddClient />
          </BranchPrivateRoute>
          <BranchPrivateRoute exact path="/branch/client/update_client">
            <BranchSidebar />
            <UpdateClient />
          </BranchPrivateRoute>
          <BranchPrivateRoute exact path="/branch/jobs">
            <BranchSidebar />
            <Jobs />
          </BranchPrivateRoute>
          <BranchPrivateRoute exact path="/branch/jobs/:id">
            <BranchSidebar />
            <Applications />
          </BranchPrivateRoute>
          <BranchPrivateRoute exact path="/branch/job_seeker">
            <BranchSidebar />
            <JobSeeker />
          </BranchPrivateRoute>
          <BranchPrivateRoute exact path="/branch/job_seeker/jobseeker_details">
            <BranchSidebar />
            <JobseekerDetails />
          </BranchPrivateRoute>
          <BranchPrivateRoute exact path="/branch/applications">
            <BranchSidebar />
            <Applications />
          </BranchPrivateRoute>
          <BranchPrivateRoute exact path="/branch/settings">
            <BranchSidebar />
            <BranchProfile />
          </BranchPrivateRoute>
          {/**************************** Client Routes ***************************/}
          <ClientPrivateRoute exact path="/client">
            <ClientSidebar />
            <ClientDashboard />
          </ClientPrivateRoute>
          <ClientPrivateRoute exact path="/client/applicants">
            <ClientSidebar />
            <Jobseeker />
          </ClientPrivateRoute>
          <ClientPrivateRoute exact path="/client/posted_jobs">
            <ClientSidebar />
            <PostedJobs />
          </ClientPrivateRoute>
          <ClientPrivateRoute exact path="/client/posted_jobs/:id">
            <ClientSidebar />
            <ClientApplications />
          </ClientPrivateRoute>
          <ClientPrivateRoute exact path="/client/settings">
            <ClientSidebar />
            <ClientProfile />
          </ClientPrivateRoute>
          <ClientPrivateRoute exact path="/client/job_post">
            <ClientSidebar />
            <JobPostMain />
          </ClientPrivateRoute>
          {/**************************** Basic Routes ***************************/}
          <Route exact path="/contact" component={Contact} />,
          <Route exact path="/signin" component={SignIn} />
          <Route path="/" component={Home} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </HashRouter>
  );
}
