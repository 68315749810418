import _ from "lodash";
import {
  GET_JOBS,
  ADD_JOB,
  UPDATE_JOB,
  DELETE_JOB,
  GET_JOBS_GLOBAL,
  JOB_SKILLS,
  GET_LINKED_BRANCHES,
  ADD_REMARKS,
  GET_REMARKS,
} from "./jobs.type";

// syntax for adding new key-values pairs to an object
const jobsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_JOBS:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      
      return {
        ...state,
        ...{
          jobs: _.mapKeys(action.payload.data, "id"),
        },
      };
    case GET_JOBS_GLOBAL:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      
      return {
        ...state,
        jobs_global: action.payload.data.jobs,
      };
    case ADD_JOB:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      
      return {
        ...state,
        jobs: {
          ...state.jobs,
          [action.payload._id]: action.payload,
        },
      };

    case UPDATE_JOB:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      
      return {
        ...state,
        jobs: {
          ...state.jobs,
          [action.payload._id]: action.payload,
        },
      };

    case DELETE_JOB:
      return {
        ...state,
        jobs: _.omit(state.jobs, action.payload),
      };

    case JOB_SKILLS:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      return {
        ...state,
        skills: {
          ..._.mapKeys(action.payload.data, "id"),
        },
      };

    case GET_LINKED_BRANCHES:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      return {
        ...state,
        linked_branches: {
          ..._.mapKeys(action.payload.data, "id"),
        },
      };

    case ADD_REMARKS:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      return {
        ...state,
        remarks: {
          ...state.remarks,
          [action.payload._id]: action.payload,
        },
      };

    case GET_REMARKS:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      return {
        ...state,
        ...{
          remarks: _.mapKeys(action.payload.chat, "id"),
        },
      };

    default:
      return state;
  }
};
export default jobsReducer;
