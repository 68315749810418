import "./App.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Routes from "./routes";
import { Redirect } from "react-router";
import Notification from "./common/notification/Notification";

function App(props) {
  const { isSignedIn, user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isSignedIn && user.type === 2) {
      return <Redirect to="/agency" />;
    } else if (isSignedIn && user.type === 3) {
      return <Redirect to="/branch" />;
    } else if (isSignedIn && user.type === 4) {
      return <Redirect to="/client" />;
    }
  }, [isSignedIn, user?.type]);

  // const [isTokenFound, setTokenFound] = useState(false);
  // getToken(setTokenFound);
  // onMessageListener()
  //   .then((payload) => {
  //     console.log("Notificy is", payload);
  //     ToasterService.showSuccessToaster(payload.notification.body);
  //   })
  //   .catch((err) => {
  //     console.log("failed: ", err);
  //     ToasterService.showErrorToaster(err);
  //   });

  return (
    <>
      <div className="App">
        <Notification />
        <Routes />
      </div>
    </>
  );
}
export default App;
