import * as React from "react";
import {
  AppBar,
  Toolbar,
  Container,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import PrimaryBtn from "../../common/buttons/primaryButton";
import logo from "./../../_assets/logo.png";
import { connect } from "react-redux";
import { logout } from "../../redux/auth/auth.action";
import authService from "../../_services/authService";
import userIcon from "./../../_assets/user.svg";

const useStyles = makeStyles({
  root: {
    background: `white !important`,
    boxShadow: `none !important`,
    position: `fixed !important`,
    zIndex: `1300 !important`,
  },
  navbarDisplayFlex: {
    display: `flex !important`,
    justifyContent: `space-between`,
  },
  navDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`,
    alignItems: `center`,
  },
  linkText: {
    textDecoration: `none`,
    color: `black`,
  },
  linkTextInner: {
    transition: `all 0.3s ease`,
    "&:hover": {
      backgroundColor: "transparent !important",
      color: `#fbbf04`,
    },
  },
  headerBtn: {
    transition: `all 0.3s ease`,
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
});
const Header = (props) => {
  const classes = useStyles();

  const navlinks = [
    { title: `Home`, path: `/` },
    { title: `Contact`, path: `/contact` },
  ];  

  const handleLogOut = () => {
    props.logout();
    props.history.push("/")
  };

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar>
        <Container
          disableGutters={true}
          maxWidth="xl"
          className={classes.navbarDisplayFlex}
        >
          <Link to="/" className="header-logo">
            <img src={logo} alt="logo" />
            <p>
              The <span>Staff</span> Hound
            </p>
          </Link>
          <List
            component="nav"
            aria-labelledby="main navigation"
            className={classes.navDisplayFlex}
            style={{ paddingBottom: "0px", paddingTop: "0px" }}
          >
            {navlinks.map(({ title, path }) => (
              <ListItem button key={title} className={classes.linkTextInner}>
                <Link to={path} key={title} className={classes.linkText}>
                  <ListItemText primary={title} />
                </Link>
              </ListItem>
            ))}
          </List>
          <List
            component="nav"
            aria-labelledby="main navigation"
            className={classes.navDisplayFlex}
            style={{ paddingBottom: "0px", paddingTop: "0px" }}
          >
            {props.auth.isSignedIn ? (
              <>
                <ListItem
                  button
                  className={classes.headerBtn}
                  onClick={handleLogOut}
                >
                  <PrimaryBtn
                    variant="outlined"
                    label="LogOut"
                    size="md"
                    id="logout"
                  />
                </ListItem>
                {props.auth?.user?.type === 2 ||
                authService?.getCurrentUser?.type === 2 ? (
                  <ListItem button className={classes.headerBtn}>
                    <Link to="/agency" className={classes.linkText}>
                      <div className="header-user-icon">
                        <img src={userIcon} alt="Icon" />
                      </div>
                    </Link>
                  </ListItem>
                ) : props.auth?.user?.type === 3 ||
                  authService?.getCurrentUser?.type === 3 ? (
                  <ListItem button className={classes.headerBtn}>
                    <Link to="/branch" className={classes.linkText}>
                      <div className="header-user-icon">
                        <img src={userIcon} alt="Icon" />
                      </div>
                    </Link>
                  </ListItem>
                ) : (
                  <ListItem button className={classes.headerBtn}>
                    <Link to="/client" className={classes.linkText}>
                      <div className="header-user-icon">
                        <img src={userIcon} alt="Icon" />
                      </div>
                    </Link>
                  </ListItem>
                )}
              </>
            ) : (
              props.history.location.pathname !== "/signin" && (
                <ListItem button className={classes.headerBtn}>
                  <Link to="/signin" className={classes.linkText}>
                    <PrimaryBtn
                      variant="outlined"
                      label="LogIn"
                      size="md"
                      id="login"
                    />
                  </Link>
                </ListItem>
              )
            )}
          </List>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps, { logout })(withRouter(Header));
