let success;
let error;
let notify;
const ToasterService = {
  showSuccessToaster: function (data) {
    success(data);
  },
  showErrorToaster: function (data) {
    error(data);
  },
  showNotifyToaster: function (data) {
    notify(data);
  },

  subscribeToToaster: function (
    success_subscribe_callBack,
    error_subscribe_callBack,
    notify_subscribe_callBack
  ) {
    success = success_subscribe_callBack;
    error = error_subscribe_callBack;
    notify = notify_subscribe_callBack;
  },
};
export default ToasterService;
