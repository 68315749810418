import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BusinessIcon from "@material-ui/icons/Business";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import SettingsIcon from "@material-ui/icons/Settings";
import { withRouter } from "react-router";
import EditAttributes from "@material-ui/icons/EditAttributes";
import { connect, useSelector } from "react-redux";
import { getJobs } from "../../redux/branch/job/job.action";

const menus = [
  {
    name: "Dashboard",
    path: "/branch",
    icon: <DashboardIcon />,
  },
  {
    name: "Client",
    path: "/branch/client",
    icon: <BusinessIcon />,
  },
  {
    name: "Job Seekers",
    path: "/branch/job_seeker",
    icon: <RecentActorsIcon />,
  },
  {
    name: "Jobs",
    path: "/branch/jobs",
    icon: <BusinessCenterIcon />,
    number: "0",
  },
  {
    name: "Applications",
    path: "/branch/applications",
    icon: <EditAttributes />,
  },
  {
    name: "Settings",
    path: "/branch/settings",
    icon: <SettingsIcon />,
  },
];

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: "0 !important",
    overflowX: "hidden",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    backgournd: "white !important",
  },
}));

const BranchSidebar = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    
    props.getJobs();
  }, []);

  const jobs = useSelector((state) => state.branch?.jobs);
  const jobs_num = Object.values(jobs);
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant={isMdUp ? "permanent" : "temporary"}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
        open={open}
        onClose={toggleDrawer}
      >
        <br />
        <br />
        <br />
        <Link to="/branch/client/add_client" className="sidebar-post">
          + Add New Client
        </Link>
        <List>
          {menus.map((menu, index) => (
            <Link key={index} to={menu.path} className="sidebar-link">
              <ListItem
                button
                className="sidebar-item"
                selected={props.location.pathname === menu.path}
              >
                <ListItemIcon>{menu.icon}</ListItemIcon>
                <ListItemText primary={menu.name} />
                {menu.number && (
                  <span className="sidebar-notify">
                    {
                      jobs_num?.filter(function (item) {
                        if (item.branch_status === "unseen") {
                          return true;
                        } else {
                          return false;
                        }
                      }).length
                    }
                  </span>
                )}
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
      <div className="sidebar-btn" onClick={toggleDrawer}>
        <MenuOpenIcon />
        <span>Sidebar Open</span>
      </div>
    </div>
  );
};

// export default withRouter(BranchSidebar);

export default connect(null, { getJobs })(
  withRouter(BranchSidebar)
);
