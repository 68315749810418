import _ from "lodash";
import {
  GET_BRANCH,
  ADD_BRANCH,
  UPDATE_BRANCH,
  DELETE_BRANCH,
  ADD_LICENSE,
  GET_LICENSE,
  GET_BRANCH_DETAILS,
} from "./branch.type";

// syntax for adding new key-values pairs to an object
const branchReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BRANCH:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      
      return {
        ...state,
        ...{
          branch: _.mapKeys(action.payload.data, "id"),
        },
      };

    case ADD_BRANCH:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      
      return {
        ...state,
        agency: {
          ...state.branch,
          [action.payload.id]: action.payload,
        },
      };

    case UPDATE_BRANCH:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      
      return {
        ...state,
        branch: {
          ...state.branch,
          [action.payload.id]: action.payload,
        },
      };

    case DELETE_BRANCH:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      
      return {
        ...state,
        branch: _.omit(state.branch, action.payload),
      };
    case GET_LICENSE:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      
      return {
        ...state,
        ...{
          license: _.mapKeys(action.payload.data, "id"),
        },
      };

    case ADD_LICENSE:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      
      return {
        ...state,
        ...{
          license: {
            ...state.license,
            [action.payload.id]: action.payload,
          },
        },
      };
    case GET_BRANCH_DETAILS:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      
      return {
        ...state,
        ...{
          branch_details: action.payload,
        },
      };
    default:
      return state;
  }
};
export default branchReducer;
