import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import { vap_id_key } from "../config.json";

var firebaseConfig = {
  apiKey: "AIzaSyCS5lEOZ9OR7RAE3js-h7QlCcTtZEh8xm4",
  authDomain: "thestaffhound-cc9fc.firebaseapp.com",
  projectId: "thestaffhound-cc9fc",
  storageBucket: "thestaffhound-cc9fc.appspot.com",
  messagingSenderId: "793517761146",
  appId: "1:793517761146:web:d1a9bded1d9e45594491c3",
  measurementId: "G-9VVR7VTNTM",
};

firebase.initializeApp(firebaseConfig);

// Retrieve firebase messaging
const messaging = firebase.messaging();

export const getToken = (setTokenFound) => {
  return messaging
    .getToken({ vapidKey: vap_id_key })
    .then((currentToken) => {
      if (currentToken) {
        // console.log("current token for client: ", currentToken);
        // setTokenFound(true);
        console.log("Current", currentToken);
        return currentToken;
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};
