import { GET_ALL } from "./home.types";

// syntax for adding new key-values pairs to an object
const homeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      
      return {
        ...state,
        ...{
          home: action.payload,
        },
      };
    default:
      return state;
  }
};
export default homeReducer;
