import { GET_JOBSEEKER } from "./jobSeeker.type";

// syntax for adding new key-values pairs to an object
const jobSeekerReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_JOBSEEKER:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      console.log("Reducer data", action.payload);
      return {
        ...state,
        ...{
          jobSeeker: action.payload,
        },
      };
    default:
      return state;
  }
};
export default jobSeekerReducer