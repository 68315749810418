import * as React from "react";
import { experimentalStyled, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const ColorButton = experimentalStyled(Button)(({ theme }) => ({
  color: "#ffffff",
  backgroundColor: "#0f5763",
  border: "none !important",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#fbbf04",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#ffffff",
    padding: "5px 25px"
  },
}));

export default function PrimaryBtn({ label, id, type, variant, size, onclick, style }) {
  const classes = useStyles();

  return (
    <ColorButton onClick={onclick} variant={variant} id={id} size={size} type={type} className={classes.root} style={style} >
      {label}
    </ColorButton>
  );
}
