import axios from "axios";
import authService from "./authService";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const http = {
  setJWT: () => {
    let token = authService.getJWT();
    axios.defaults.headers.common["Authorization"] = token;
  },
  get: axios.get,
  put: axios.put,
  post: axios.post,
  delete: axios.delete,
};

export default http;
