import { Route, Redirect } from "react-router-dom";
import authService from "../_services/authService";

export default function ClientPrivateRoute({
	component: Component,
	children,
	render,
	...rest
}) {
	const user = authService.getCurrentUser();
	return (
		<Route
			{...rest}
			render={({ location }) =>
				user && user?.type === 4 ? (
					children
				) : (
					<Redirect
						to={{
							pathname: "/client",
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
}
