import authService from "../../_services/authService";
import { SIGNED_IN, SIGNED_OUT } from "./auth.types";

const user = authService.getCurrentUser();

const INITIAL_STATE = user
  ? { isSignedIn: true, user }
  : { isSignedIn: false, user: null };

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGNED_OUT:
      return { ...state, isSignedIn: false, user: null };
    case SIGNED_IN:
      return {
        ...state,
        isSignedIn: action.payload.data ? true : false,
        user: action.payload && action.payload.data,
      };
    default:
      return state;
  }
};
export default authReducer;
