import React, { useEffect, useState } from "react";
import { getToken } from "../../_services/firebase";
import firebase from "@firebase/app-compat";
import ToasterService from "../../_services/toasterService";

const Notification = (props) => {


  useEffect(() => {
    const messaging = firebase.messaging();
    new Promise((resolve) => {
      messaging.onMessage((payload) => {
        resolve(payload);
        console.log("The payload is here", payload);
        let data = {
          title: payload.notification.title,
          body: payload.notification.body,
        };
        ToasterService.showNotifyToaster(data);
      });
    });
  }, []);

  return <div></div>;
};

Notification.propTypes = {};

export default Notification;
