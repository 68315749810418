import _ from "lodash";
import { APPROVE_JOBS } from "./job.type";
import { ADD_JOBS, DELETE_JOBS, GET_JOBS, UPDATE_JOBS } from "./job.type";

// syntax for adding new key-values pairs to an object
const jobReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_JOBS:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      
      return {
        ...state,
        ...{
          ..._.mapKeys(action.payload.data, "id"),
        },
      };

    case ADD_JOBS:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      
      return {
        ...state,
        branch: {
          ...state.jobs,
          [action.payload.id]: action.payload,
        },
      };

    case UPDATE_JOBS:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      
      return {
        ...state,
        branch: {
          ...state.jobs,
          [action.payload.id]: action.payload,
        },
      };

    case DELETE_JOBS:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      
      return {
        ...state,
        state: _.omit(state.jobs, action.payload),
      };

    case APPROVE_JOBS:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      
      return {
        ...state,
        ...state.jobs,
        [action.payload[0].id]: action.payload[0],
      };
    default:
      return state;
  }
};
export default jobReducer;
