import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import PersonIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import { withRouter } from "react-router";

const menus = [
  {
    name: "Dashboard",
    path: "/client",
    icon: <DashboardIcon />,
  },
  {
    name: "Posted Jobs",
    path: "/client/posted_jobs",
    icon: <BusinessCenterIcon />,
  },
  {
    name: "Applicants",
    path: "/client/applicants",
    icon: <PersonIcon />,
  },
  {
    name: "Settings",
    path: "/client/settings",
    icon: <SettingsIcon />,
  },
];

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: "0 !important",
    overflowX: "hidden",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    backgournd: "white !important",
  },
}));

const ClientSidebar = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant={isMdUp ? "permanent" : "temporary"}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
        open={open}
        onClose={toggleDrawer}
      >
        <Link to="/client/job_post" className="sidebar-post" style={{marginTop: "100px"}}>
          + Post a New Job
        </Link>
        <List>
          {menus.map((menu, index) => (
            <Link key={index} to={menu.path} className="sidebar-link">
              <ListItem
                button
                className="sidebar-item"
                selected={props.location.pathname === menu.path}
              >
                <ListItemIcon>{menu.icon}</ListItemIcon>
                <ListItemText primary={menu.name} />
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
      <div className="sidebar-btn" onClick={toggleDrawer}>
        <MenuOpenIcon />
        <span>Sidebar Open</span>
      </div>
    </div>
  );
};

export default withRouter(ClientSidebar);
