import { apiEndpoint } from "../../../config.json";

import {
  ADD_JOBS,
  APPROVE_JOBS,
  DELETE_JOBS,
  GET_JOBS,
  UPDATE_JOBS,
} from "./job.type";
import http from "../../../_services/http";

export const getJobs = () => async (dispatch) => {
  http.setJWT();
  const response = await http.get(apiEndpoint + "/getJobsRequestFromClient");

  dispatch({
    type: GET_JOBS,
    payload: response.data,
  });
};

export const addingClients = (ClientsForm) => async (dispatch, getState) => {
  let response;
  http.setJWT();
  try {
    response = await http.post(apiEndpoint + "/AddClient", ClientsForm);
    dispatch({
      type: ADD_JOBS,
      payload: response.data.data,
    });
  } catch (e) {
    throw e.response;
  }
};

export const updateClient = (ClientsPost) => async (dispatch) => {
  let response;
  try {
    response = await http.post(apiEndpoint + "/UpdateClients", ClientsPost);
    dispatch({
      type: UPDATE_JOBS,
      payload: response.data,
    });
  } catch (e) {
    throw e.response;
  }
};

export const deleteClient = (id) => async (dispatch) => {
  let response;
  let data = {
    id: id,
  };
  try {
    response = await http.post(apiEndpoint + "/DeleteClients", data);
    dispatch({
      type: DELETE_JOBS,
      payload: id,
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const jobResponse = (job_id, status) => async (dispatch, getState) => {
  http.setJWT();
  let response;
  try {
    response = await http.post(apiEndpoint + "/jobResponse", {
      id: job_id,
      status: status,
      notify_type: "job_status",
      title: "Job status updated",
      body: `Your job has been ${status}!`,
    });
    dispatch({
      type: APPROVE_JOBS,
      payload: response.data.data,
    });
  } catch (e) {
    throw e.response;
  }
};
