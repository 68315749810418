import { combineReducers } from "redux";
import { reducer as reducerForm } from "redux-form";
import auth from "./auth/auth.reducer";
import branchReducer from "./agency/branch/branch.reducer";
import clientReducer from "./branch/client.reducer";
import jobReducer from "./branch/job/job.reducer";
import jobPostReducer from "./client/job/jobPost.reducer";
import jobsReducer from "./jobs/jobs.reducer";
import jobSeekerReducer from "./branch/jobSeeker/jobSeeker.reducer";
import homeReducer from "./home/home.reducer";
import jobSeekersReducer from "./client/jobSeeker/jobSeekers.reducer";
import applicationsReducer from "./branch/applications/applications.reducer";
import applicantsReducer from "./client/applications/applications.reducer";

const branchReducers = combineReducers({
  client: clientReducer,
  jobs: jobReducer,
  jobSeeker: jobSeekerReducer,
  applications: applicationsReducer,
});
const clientReducers = combineReducers({
  jobs: jobPostReducer,
  jobSeeker: jobSeekersReducer,
  applicants: applicantsReducer,
});

const rootReducer = combineReducers({
  form: reducerForm,
  auth: auth,
  home: homeReducer,
  agency: branchReducer,
  branch: branchReducers,
  client: clientReducers,
  jobs: jobsReducer,
});
export default rootReducer;
