import _ from "lodash";
import {
  GET_CLIENT,
  ADD_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  GET_CLIENT_DETAILS,
} from "./client.type";

// syntax for adding new key-values pairs to an object
const clientReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CLIENT:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      
      return {
        ...state,
        ...{
          ..._.mapKeys(action.payload.data, "id"),
        },
      };

    case ADD_CLIENT:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      
      return {
        ...state,
        ...state.client,
        [action.payload.id]: action.payload,
      };

    case UPDATE_CLIENT:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      
      return {
        ...state,
        ...state.client,
        [action.payload.id]: action.payload,
      };

    case DELETE_CLIENT:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
 
      return {
        ...state,
        ...{
          ..._.omit(state, action.payload),
        },
      };
    case GET_CLIENT_DETAILS:
      // this is how the array gets transformed into a lookup object
      // map Keys returns a big object, and we want to add the key-value pairs and add to the new big overall object
      
      return {
        ...state,
        ...{
          client_details: action.payload,
        },
      };
    default:
      return state;
  }
};
export default clientReducer;
