import authService from "../../_services/authService";
import {
  SIGNED_IN,
  SIGNED_OUT,
} from "./auth.types";

export const login = (email, password, device_token) => async (dispatch) => {
  console.log("Token deivce", device_token)
  try {
    await authService.login(email, password, device_token);
    let user = authService.getCurrentUser();
    dispatch({
      type: SIGNED_IN,
      payload: { data: user },
    });
  } catch (e) {
    throw e.response;
  }
};

export const signin = (values) => async (dispatch) => {
  try {
    await authService.signup(values);
    dispatch({
      type: SIGNED_IN,
      payload: true,
    });
  } catch (e) {
    throw e.response;
  }
};

export const signedIn = () => (dispatch) => {
  try {
    let user = authService.getCurrentUser();
    dispatch({
      type: SIGNED_IN,
      payload: { data: user },
    });
  } catch (e) {
    throw e.response;
  }
};

export const logout = () => async (dispatch) => {
  try {
    await authService.logout();
    dispatch({
      type: SIGNED_OUT,
      payload: false,
    });
  } catch (e) {
    throw e.response;
  }
};
