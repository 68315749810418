import React from "react";
import ToasterService from "../_services/toasterService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Toaster() {
  const [position, setPosition] = React.useState("");

  const successToaster = (toaster_content) => {
    setPosition("bottom-right");
    toast.success(toaster_content, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const errorToaster = (toaster_content) => {
    setPosition("bottom-right");
    toast.error(toaster_content, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const notifyToaster = (toaster_content) => {
    setPosition("top-right");
    toast.info(
      <>
        <b>{toaster_content.title}</b> <p style={{margin: "0"}}>{toaster_content.body}</p>
      </>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };
  ToasterService.subscribeToToaster(
    successToaster,
    errorToaster,
    notifyToaster
  );
  return (
    <ToastContainer
      position={position}
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      theme="colored"
    />
  );
}
