import http from "./http";
import { apiEndpoint } from "../config.json";

const apiUrl = apiEndpoint;

const tokenKey = "token";
const userKey = "user";
const deviceToken = "device_token";
const authService = {
  login: async (email, password, device_token) => {
    const { data } = await http.post(apiUrl + "/login", {
      email,
      password,
      password_confirmation: password,
      device_token: device_token,
    });
    let alldata = { ...data.data, ...data.record[0] };
    localStorage.setItem(tokenKey, "Bearer " + data.token);
    localStorage.setItem(userKey, JSON.stringify(alldata));
    localStorage.setItem(deviceToken, device_token);
  },

  signup: async (values) => {
    const { data } = await http.post(apiUrl + "/register", {
      name: values.name,
      owner_first_name: values.owner_first_name,
      owner_last_name: values.owner_last_name,
      company_name: values.company_name,
      company_web: values.company_web,
      phone: values.phone,
      email: values.email,
      password: values.password,
      password_confirmation: values.password_confirmation,
      street1: values.street1,
      street2: values.street2,
      city: values.city,
      state: values.state,
      zip_code: values.zip_code,
      country: values.country,
      hours_start: values.hours_start,
      hours_end: values.hours_end,
      type: values.type,
      description: values.description,
    });
    localStorage.setItem(tokenKey, "Bearer " + data[0].token);
    localStorage.setItem(userKey, JSON.stringify(data[0].user));
  },

  logout: () => {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(userKey);
    localStorage.clear();
  },

  getCurrentUser: () => {
    try {
      const user = localStorage.getItem(userKey);
      return JSON.parse(user);
    } catch (error) {
      return null;
    }
  },

  getJWT: () => {
    try {
      const token = localStorage.getItem(tokenKey);
      return token;
    } catch (error) {
      return null;
    }
  },

  forgetPassword: async (email) => {
    const { data } = await http.post(apiUrl + "/forget-password", {
      email,
    });
    return data;
  },

  resetPassword: async (data) => {
    const { data: result } = await http.post(apiUrl + "/updatePassword", {
      data,
    });
    return result;
  },
  isLinkExpire: async (data) => {
    const { data: result } = await http.post(apiUrl + "/is_link_expire", {
      email: data,
    });
    return result;
  },
};

// export default {
//   login,
//   signup,
//   logout,
//   getCurrentUser,
//   getJWT,
//   forgetPassword,
//   resetPassword,
//   isLinkExpire,
// };

export default authService;
